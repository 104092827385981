import axios from 'axios';
import u, {
  ClearRedirect,
  CreateProfileActions,
  GetAuthActionTypes,
  GetUser,
  GetUserCancelled,
  Login,
  LoginArg,
  Logout,
  PasswordResetArg,
  RedirectTo,
  RequestPasswordReset,
  SetIsPasswordResendSuccess,
  UpdatePassword,
  VerifyEmail,
  VerifyPasswordReset,
  ChangePassword,
  DeleteUser,
  UpdateUserState,
  CompleteOnboarding,
} from './userActionTypes';
import { getAxiosRequestConfig } from '../utilities/http';

export function getCurrentUser(redirectTo?: string): GetUser | GetUserCancelled {
  if (!localStorage.getItem('csrfToken')) {
    return {
      type: u.GET_USER_CANCELLED,
      meta: { redirectTo },
    };
  }
  return {
    type: u.GET_USER,
    meta: { redirectTo },
    payload: axios.get(
      `${process.env.REACT_APP_API_BASE_URL || ''}/api/users/me`,
      getAxiosRequestConfig()
    ),
  };
}

export function login({ email, password, application, destinationURL }: LoginArg): Login {
  return {
    type: u.LOGIN,
    meta: { destinationURL },
    payload: axios.post(
      `${process.env.REACT_APP_API_BASE_URL || ''}/api/login`,
      {
        email,
        password,
        application,
        rememberMe: true,
      },
      { withCredentials: true }
    ),
  };
}

export function redirectTo(path: string): RedirectTo {
  return {
    type: u.REDIRECT_TO,
    meta: path,
  };
}

export function clearRedirect(): ClearRedirect {
  return {
    type: u.CLEAR_REDIRECT,
  };
}

export function logout(destinationURL?: string): Logout {
  return {
    type: u.LOGOUT,
    meta: { destinationURL },
    payload: axios.post(
      `${process.env.REACT_APP_API_BASE_URL || ''}/api/logout`,
      {},
      getAxiosRequestConfig()
    ),
  };
}

export function deleteUser(userID: string, email: string, redirectTo = '/register'): DeleteUser {
  return {
    type: u.DELETE_USER,
    meta: { redirectTo },
    payload: axios.post(
      `${process.env.REACT_APP_API_BASE_URL || ''}/api/v3/users/delete`,
      {
        id: userID,
        email,
      },
      getAxiosRequestConfig()
    ),
  };
}

export function verifyEmail(emailVerificationCode: string, saveCSRF: boolean): VerifyEmail {
  return {
    type: u.VERIFY_EMAIL,
    meta: { saveCSRF },
    payload: axios.post(`${process.env.REACT_APP_API_BASE_URL || ''}/api/verifyEmail`, {
      emailVerificationCode,
    }),
  };
}

export function requestPasswordReset(arg: PasswordResetArg): RequestPasswordReset {
  const { email, destinationURL, application } = arg;
  return {
    type: u.REQUEST_PASSWORD_RESET,
    meta: arg,
    payload: axios.post(
      `${process.env.REACT_APP_API_BASE_URL || ''}/api/request-password-reset`,
      {
        email,
        application,
        destinationURL,
      },
      getAxiosRequestConfig()
    ),
  };
}

export function verifyPasswordReset(emailVerificationCode: string): VerifyPasswordReset {
  return {
    type: u.VERIFY_PASSWORD_RESET,
    payload: axios.post(`${process.env.REACT_APP_API_BASE_URL || ''}/api/verifyPasswordReset`, {
      emailVerificationCode,
    }),
  };
}

export function resetPassword(password: string): UpdatePassword {
  const data = new FormData();
  data.append('password', password);

  return {
    type: u.UPDATE_PASSWORD,
    payload: axios.post('/api/updatePassword', data, getAxiosRequestConfig()),
  };
}

export function changePassword(
  email: string,
  currentPassword: string,
  newPassword: string,
  destinationURL = '/password-reset-success'
): ChangePassword {
  const data = JSON.stringify({
    email,
    currentPassword,
    newPassword,
  });
  return {
    type: u.CHANGE_PASSWORD,
    payload: axios.post(
      `${process.env.REACT_APP_API_BASE_URL || ''}/api/updatePasswordLoggedIn`,
      data,
      getAxiosRequestConfig()
    ),
    meta: { destinationURL },
  };
}

export function setIsPasswordResendSuccess(
  isPasswordResendSuccess: boolean
): SetIsPasswordResendSuccess {
  return {
    type: u.SET_IS_PASSWORD_RESEND_SUCCESS,
    payload: isPasswordResendSuccess,
  };
}

export function getAuth(token: string): GetAuthActionTypes {
  return {
    type: u.GET_AUTH,
    payload: axios.get(
      `${process.env.REACT_APP_API_BASE_URL || ''}/api/auth`,
      getAxiosRequestConfig()
    ),
  };
}

export function KeepSessionAlive() {
  return {
    payload: axios.get(
      `${process.env.REACT_APP_API_BASE_URL || ''}/api/keep-session-alive`,
      getAxiosRequestConfig()
    ),
  };
}

export function createProfile(
  fullname: string,
  password: string,
  app: string
): CreateProfileActions {
  const data = new FormData();
  data.append('fullName', fullname);
  data.append('password', password);
  data.append('app', app);

  return {
    type: u.CREATE_PROFILE,
    payload: axios.post('/api/createProfile', data, getAxiosRequestConfig()),
    meta: { destinationURL: '/welcome' },
  };
}

export function resetUserDelete(isUserDeleted: boolean): UpdateUserState {
  return {
    type: u.UPDATE_USER_STATE,
    payload: isUserDeleted,
  };
}

export function completeOnboarding(): CompleteOnboarding {
  return {
    type: u.COMPLETE_ONBOARDING,
    payload: axios.post(
      `${process.env.REACT_APP_API_BASE_URL || ''}/api/completeOnboarding`,
      {},
      getAxiosRequestConfig()
    ),
  };
}

export function verifyOTP(OTP: string, SkipMFA: boolean, destinationURL = '/provider-search') {
  return {
    type: u.VERIFY_OTP,
    meta: { destinationURL },
    payload: axios.post(
      `${process.env.REACT_APP_API_BASE_URL || ''}/api/verifyOTP`,
      {
        otp: OTP,
        // eslint-disable-next-line @typescript-eslint/camelcase
        skip_mfa: SkipMFA,
      },
      getAxiosRequestConfig()
    ),
  };
}

export function resendOTP() {
  return {
    type: u.RESEND_OTP,
    payload: axios.get(
      `${process.env.REACT_APP_API_BASE_URL || ''}/api/resendOTP`,
      getAxiosRequestConfig()
    ),
  };
}
